<template>
  <div v-if="canAccess('rp_porcentaje_planes')" class="card">
    <BlockUI :blocked="loading" :fullScreen="true" style="position:absolute;top:50%;left:50%;">
      <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
    </BlockUI>
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>
    <div class="p-col-12">
      <h6>Informe Porcentaje de Planes</h6>
    </div>
    <div class="p-col-12">
      <div class="p-text-right p-fluid  p-ml-2">
        <div class="p-grid p-fluid  p-ml-2">
          <div class="p-col-12 p-md-2 p-mt-2" style="padding-top: 20px;">
                    <span class="p-float-label df" style="margin-right: 1px;">
                      <Dropdown id="country" v-model="country" :options="countries" optionLabel="country"
                                @change="selectedMonth=null;selectedWeek=null;subsidiary=null;itemsTotal=[];counterRegisters = 0;filter()"
                      />
                      <label for="country">País</label>
                  </span>
          </div>
          <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
                <span class="p-float-label df">
                    <Dropdown id="subsidiary" v-model="subsidiary" :options="subsidiaries"
                              @change="itemsTotal=[];counterRegisters = 0;"
                              optionLabel="name"/>
                    <label for="subsidiary">Filial</label>
                </span>
          </div>

          <div class="p-col-12 p-md-2 p-mt-2" style="padding-top: 20px;">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedMonth" :options="months" optionLabel="month_process"
                                                 @change="selectedWeek=null;pLiquidations=[];filter()"/>
                                        <label for="months">Mes</label>
                                    </span>
          </div>
          <div class="p-col-12 p-md-2 p-mt-2" style="padding-top: 20px;">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedWeek" :options="weeks" optionLabel="week_process"
                                                 @change="pLiquidations=[];filter()"/>
                                        <label for="weeks">Semana</label>
                                    </span>
          </div>


          <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
            <span class="p-float-label df" style="margin-right: 3px">
                          <Calendar autocomplete="off" :maxDate="new Date()" aria-autocomplete="false"
                                    id="start_date" :showIcon="true" v-model="date_start"
                                    dateFormat="yy-mm-dd"
                                    :yearNavigator="true" yearRange="2019:2040"/>
                          <label for="start_date">Fecha Inicio</label>
            </span>
          </div>
          <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
              <span class="p-float-label df" style="margin-left: 3px">
                  <Calendar :maxDate="new Date()" :minDate="date_start" autocomplete="off"
                            id="end_date" :showIcon="true" v-model="date_end" dateFormat="yy-mm-dd"
                            :yearNavigator="true" yearRange="2019:2040" class="p-inputtext-sm"/>
                  <label for="end_date">Fecha Fin</label>
              </span>
          </div>
          <div style="padding-top: 20px;" class="p-col-12 p-md-1 p-mt-2">
            <Button label="Buscar" class="p-button-success  df"
                    @click="getData()"/>
          </div>
          <div style="padding-top: 20px;" class="p-col-12 p-md-1 p-mt-2">
            <Button label="Exportar" class="p-button-secondary df"
                    @click="exportReport('XLS')"/>

          </div>
          <div style="padding-top: 20px;" class="p-col-12 p-md-1 p-mt-2">
            <Button label="Imprimir" class="p-button-info df"
                    @click="exportReport('PDF')"/>

          </div>

        </div>
      </div>
    </div>

    <div class="card">
      <h4 style="width: 100%">Consolidado de Matriculas OK por Plan</h4>
      <div class="table-responsive">
        <table class="table table-hover" style="width: 100%">
          <thead>
          <tr class="text-center">
            <th scope="col" class="thC">Plan</th>
            <th scope="col" class="thC">Valor del Plan</th>
            <th scope="col" class="thC">Cantidad de Patr&iacute;culas</th>
            <th scope="col" class="thC">% de Participaci&oacute;n</th>
          </tr>
          </thead>
          <tbody class="text-center tableBody">
          <tr v-for="(item,idx) in itemsTotal" :key="idx">
            <td class="tdC">{{ item.plan }}</td>
            <td class="tdC">{{ formatMoney(item.cost) }}</td>
            <td class="tdC">{{ item.registers }}</td>
            <td class="tdC">{{(item.registers*100/(counterRegisters??1)).toFixed(2)}}%</td>
          </tr>
          <tr >
            <td colspan='2' class="tdC" style='border-top: 1px solid black'><b>Total de Matr&iacute;culas en este periodo</b></td>
            <td class="tdC" style='text-align: center;border-top: 1px solid black'>{{counterRegisters}}</td>
            <td class="tdC" style='border-top: 1px solid black'></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import bouncer from "../../../helpers/bouncer";
import catalogService from "../../core/service/catalog.service";
import moment from "moment";
import service from "../service/report.service";
import FileSaver from 'file-saver';

export default {
  mixins: [bouncer],
  name: "PercentagePlansReport",
  data() {
    return {
      loading: false,
      date_end: null,
      date_start: null,
      date_end_max: null,
      subsidiary: null,
      country: null,
      countries: [],
      itemsTotal: [],
      counterRegisters:0,
      lblSubs: [],
      service: service('percentage-plans'),
      selectedMonth: null,
      selectedWeek: null,
    }
  },
  mounted() {
    if (this.canAccess('rp_porcentaje_planes')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Estadísticas', url: "javascript:void(0);"}]);

      const date = new Date();
      this.date_end = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
      this.date_end_max = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
      this.date_start = new Date(date.getFullYear(), 0, 1);

      catalogService.get(`select/countries/subsidiaries-months-verif`).then(response => {
        this.countries = response.data;
        this.loading = false;
        if (!this.isAdmin) {
          this.country = this.countries[0];
        }
      });

    }
  },
  methods: {
    formatMoney(val) {
      return (new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })).format(val);
    },
    formatDate(dat) {
      return moment(dat).format('YYYY-MM-DD');
    },
    filter() {
      this.loading = true;
      this.getCountries()
    },
    getCountries() {
      catalogService.get(`select/countries/subsidiaries-months-verif`).then(response => {
        this.countries = response.data;
        this.loading = false;
      });
    },
    exportReport(command) {
      if (this.country == null) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'El campo pais es obligatorio',
          life: this.$utils.toastLifeTime()
        });
        return;
      }
      this.loading = true;
      this.service.getPercentagePlans({
        date_start: moment(this.date_start).format("YYYY-MM-DD"),
        date_end: moment(this.date_end).format("YYYY-MM-DD"),
        month: this.selectedMonth?.id,
        week: this.selectedWeek?.id,
        country: this.country?.id,
        subsidiary: this.subsidiary?.id,
        export: command
      })
          .then(x => {
            if (command == 'PDF') {
              this.info = x.data;
              var byteCharacters = atob(x.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], {type: "application/pdf"});
              this.loading = false;
              FileSaver.saveAs(blob, "NaturaEnglishReport.pdf");
            } else {
              this.loading = false;
              window.open(x.data.url_file)
            }

          }).catch((err) => {
        this.loading = false;
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      })

    },
    getData() {
      if (this.country == null && this.isAdmin) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'El campo pais es obligatorio',
          life: this.$utils.toastLifeTime()
        });
      } else {
        if (!moment(this.date_start).isValid() || !moment(this.date_end).isValid()) {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Los campos de fecha son obligatorios para el reporte',
            life: this.$utils.toastLifeTime()
          });
        } else {
          if (moment(this.date_start) > moment(this.date_end)) {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'El campo fecha hasta debe ser mayor o igual que el campo fecha desde',
              life: this.$utils.toastLifeTime()
            });
          } else {
            this.loading = true;
            this.itemsTotal = []
            this.counterRegisters = 0
            this.service.getPercentagePlans({
              date_start: moment(this.date_start).format("YYYY-MM-DD"),
              date_end: moment(this.date_end).format("YYYY-MM-DD"),
              month: this.selectedMonth?.id,
              week: this.selectedWeek?.id,
              country: this.country?.id,
              subsidiary: this.subsidiary?.id
            }).then(x => {
              this.loading = false;
              if (x.data.table?.length === 0) {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'No hay registros por visualizar con los valores escogidos',
                  life: this.$utils.toastLifeTime()
                });
              } else {
                  this.itemsTotal=x.data.table;
                  this.counterRegisters=x.data.total;
              }

            }).catch(err => {
              this.loading = false;
              const message = err.response.data;
              let error = this.$utils.formatError(message);
              this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
            })
          }
        }
      }

    }
  },
  computed: {
    ...mapState('auth', ['isAdmin']),
    ...mapGetters('bread', ['breadcrumb']),
    subsidiaries() {
      var subsidiaries = this.country ? this.country.subsidiaries : [];


      var elements = [];
      if (subsidiaries.length > 0) {
        for (var sub in subsidiaries) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.lblSubs[sub.id] = sub.description;
        }
        var obj = {
          id: null,
          employee_id: 0,
          city_id: subsidiaries[0].city_id,
          name: "Todas las Filiales",
          created_at: null,
          deleted_at: null,
          laravel_through_key: subsidiaries[0].laravel_through_key,
          updated_at: null
        };
        elements = [obj, ...subsidiaries]
      }
      return elements;
    },
    months() {
      const result = this.country ? this.country.months : [];
      if (result.length === 0 && this.country) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Atención',
          detail: 'No existen meses asociados al país',
          life: this.$utils.toastLifeTime()
        });
      }
      return result;
    },
    weeks() {
      const result = this.selectedMonth ? this.selectedMonth.weeks : [];
      if (result.length === 0 && this.selectedMonth) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Atención',
          detail: 'No existen semanas asociadas al mes',
          life: this.$utils.toastLifeTime()
        });
      }
      return result;
    }
  }
}
</script>

<style>

.p-dropdown .p-dropdown-trigger {
  height: 33px;
}

.thReport {

  padding: 2px !important;
  border-width: 1px !important;
}

.tdReport {
  padding: 2px !important;
  border-width: 1px !important;
}

div.tableC > div > div > table {
  table-layout: auto !important;
}

.df {
  margin-left: 3px !important;
  margin-right: 4px !important;

}

span.p-calendar.p-component.p-inputwrapper.p-calendar-w-btn.p-inputwrapper-filled {
  height: 35px !important;
}


.table-responsive {
  overflow-x: auto;
}

thead tr:nth-child(1) th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.thC {
  background: #f8f9fa !important;
}

.tdC {
  padding: 4px !important;
  text-align: center;
}

.p-dropdown-label {
  text-align: left;
}
</style>
